import React from 'react';
import { Apple, Castbox, Discord, Google, Instagram, JetoneLogo, JetoneText, Spotify, Stitcher, Twitch, Twitter } from '../../assets' 
import './Home.css'

const Home: React.FunctionComponent = () => {
    return (
        <div className="Home">
            <a href="https://twitter.com/jetonepodcast">
                    <img src={Twitter} className="Home-social" alt="twitter" />
            </a>
            <a href="https://www.instagram.com/jetonepodcast/">
                    <img src={Instagram} className="Home-social" alt="instagram" />
            </a>
            <a href="https://discord.gg/BH8W7qa">
                    <img src={Discord} className="Home-social" alt="discord" />
            </a>
            <a href="https://www.twitch.tv/jetone">
                    <img src={Twitch} className="Home-social" alt="twitch" />
            </a>
            <div>
                <img src={JetoneLogo} className="Home-logo" alt="logo" />
                <img src={JetoneText} className="Home-text" alt="logoText" />
            </div>

            <a href="https://podcasts.apple.com/us/podcast/je-tone/id1539567969">
                <img src={Apple} className="Home-link" alt="apple" />
            </a>
            <a href="https://castbox.fm/channel/id3508504?utm_source=website&utm_medium=dlink&utm_campaign=web_share&utm_content=Je-Tone-CastBox_FM" >
                <img src={Castbox} className="Home-link" alt="castbox" />
            </a>
            <a href="https://podcasts.google.com/feed/aHR0cDovL3Jzcy5jYXN0Ym94LmZtL2V2ZXJlc3QvMjE2OGQ5OGJjNTRmNGFhMzhhNDM5MWM3YzNiMDc4NzYueG1s?sa=X&ved=0CAMQ4aUDahcKEwiQh4yGp6vtAhUAAAAAHQAAAAAQBw" >
                <img src={Google} className="Home-link"  alt="google" />
            </a>
            <a href="https://open.spotify.com/show/43DLvUrSp8Ybv5YZksD8Uk?si=npB6E2udSLugAjWeCyixVg" >
                <img src={Spotify} className="Home-link" alt="spotify" />
            </a>
            <a href="https://www.stitcher.com/show/jetone">
                <img src={Stitcher} className="Home-link" alt="stitcher" />
            </a>
        </div>
    )
}

export default Home